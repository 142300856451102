import { TextInput, InputLabel, SelectInput, TextareaInput, Footer } from '../components'
import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { useState, useLayoutEffect, useEffect } from 'react';
import { createResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';

export const EventForm = () => {

   // for language translation
   const { t } = useTranslation();

  const { user } = useUser();  // Get currently loggedIn user
  const { envState, setEnvState } = useEnv(); // Get Environment context
  const { publish } = useEvents(); // publish - dispatches events
  const { handleSubmit } = useHandleSubmit(); // Shared submit handler
  const today = new Date().toISOString().split('T')[0]; // Todays' date


  // --------------------- start deciding how to handle queued record if any -------------------

  const [queuedRecord, setFarmRecord] = useState(null);
  const [initFormState, setInitFormState] = useState(null);

  useEffect(() => {
      // Retrieve record from local storage using the special key if was a failed queue
      const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
      if (queuedFarmRecord) {
          const parsedFarmRecord = JSON.parse(queuedFarmRecord);
          setFarmRecord(parsedFarmRecord);

          // Delete the special key and its corresponding data from local storage
          localStorage.removeItem('queuedFarmRecord');
      }
  }, []);


  useEffect(() => {
      if (queuedRecord) {
          // If queueRecord exists, initialize initFormState with its data
          setInitFormState({ ...queuedRecord, user_id: user.id });

      } else {
          // If queueRecord is null, initialize initFormState with default values
          setInitFormState({
            cattle_id: '',
            date: today,
            type: 'Treatment',
            other_type: '',
            medicine: '',
            treatment: '',
            repeat_date: '',
            notes: '',
            bulk_single: "Single",
            user_id: user.id,
          });
      }
  }, [queuedRecord]);

  // ensuring initFormState is initialized before rendering a page
  useEffect(() => {
        if (initFormState !== null) {
          setFormState(initFormState);
        }
  }, [initFormState]);

  const resetFormState = () => { 
    setFormState(initFormState);
    setFarmRecord(null);
  }

  const [formState, setFormState] = useState(initFormState);

  // --------------------- end deciding how to handle queued record if any -------------------
    
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState('');

    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_events'), backPath: "", homePath: "/fe"}));
        return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []);

    // I want to handle Bulk seeded cattle in this selected. this is a new version that handles Bulk option
    const handleChange = (inputKey, inputValue) => {
      if (inputKey === 'bulk_single') {
        if (inputValue === 'Bulk') {

          setFormState({ ...formState, [inputKey]: inputValue, cattle_id: 'Bulkcattle' });
  

        } else if (inputValue === 'Single') {
          
          setFormState({ ...formState, [inputKey]: inputValue, cattle_id: '' });


        }
      } else {
        
        setFormState({ ...formState, [inputKey]: inputValue });

      }
    };




    // const resetFormState = () => { setFormState(initFormState) }

    const submitEventForm = (e) => {
      e.preventDefault(); 
      if (formState?.type === 'Other' && formState?.other_type === ''){
        setResponseErrors({other_type: [t('events_page.enter_other_event_type')]}) 
      }else{
        // treatment cannot be emppty when medicine is provided, for PG case, we assign it to medicine
        if (formState?.type === 'Prostaglandin') formState.treatment = formState?.medicine;

        handleSubmit(
          e,
          formState,
          createResource,
          envState.online,
          process.env.REACT_APP_EVENTS,
          setResponseErrors,
          setCreateNotice,
          resetFormState,
          publish
        )
      }

    }


    if (initFormState === null) {
      return <div>{t("error_message.loading")}</div>; // or any loading indicator
    }

    return (
        <div className="mt-[100px] mb-[100px]">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
            <form onSubmit={submitEventForm} autoComplete="off">
                <div className="overflow-hidden sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="bulk_single_label"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t("navigation_element.nav_item_title_select_bulk_single")} 
                            />
                            <SelectInput
                              focus={false}
                              inputName="bulk_single"
                              inputId="bulk_single"
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.bulk_single || 'Single'}
                              handleChange={handleChange}
                              choices={[
                                { key: "Single", label: t("navigation_element.nav_item_title_single") }, 
                                { key: "Bulk", label: t("navigation_element.nav_item_title_bulk")  },
                              ]}
                            />
                        </div>

                        {/* Event Date - date */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="date"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('events_page.event_date')}
                          />
                          <TextInput
                            focus={false}
                            inputType="date"
                            inputName="date"
                            inputId="date"
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.date}
                            handleChange={handleChange}
                          />
                          {showFieldErrors(responseErrors.date)}
                        </div>  

                        {/** // I want to handle Bulk seeded cattle in this selected. this is a new version that handles Bulk option */}
                        {/* Cattle Id field - text*/}
                        {formState?.bulk_single === 'Single' && (
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="cattle_id"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t("page_elements.tag")}
                            />
                            <TextInput
                              focus={true}
                              inputType="text"
                              inputName="cattle_id"
                              inputId="cattle_id"
                              hint={t("milking_page.enter_cattle_tag")}
                              inputPattern="^[a-zA-Z0-9]{1,10}$"
                              isAutoComplete="on"
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.cattle_id}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.cattle_id)}
                          </div>
                        )}


                        {/* Event type - select*/}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="type"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('events_page.event_type')}
                          />
                        <SelectInput
                            focus={false}
                            inputName="type"
                            inputId="type"
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.type}
                            handleChange={handleChange}
                            choices={[
                              {key: 'Vaccination', label: t('events_page.vaccination') },
                              {key: 'Serviced', label: t('events_page.serviced') },
                              {key: 'Calved', label: t('events_page.calved') },
                              {key: 'Heat', label: t('events_page.heat') },
                              {key: 'Treatment', label: t('events_page.treatment') },
                              {key: 'Prostaglandin', label: t('events_page.hormone') },  
                              // {key: 'Disease', label: t('events_page.disease') },
                              {key: 'De-worm', label: t('events_page.de_worm') },
                              {key: 'Dried', label: t('events_page.dried') },
                              {key: 'Castration', label: t('events_page.castration') },
                              {key: 'Culled', label: t('events_page.culled') },
                              {key: 'Sold', label: t('events_page.sold') },
                              {key: 'De-horned', label: t('events_page.de_horned') },
                              {key: 'Died', label: t('events_page.died') },
                              {key: 'Other', label: t('events_page.other_type') },
                            ]}
                          />
                          {showFieldErrors(responseErrors?.type)}
                        </div>

                        {/* Other type - text */}
                        {formState?.type === 'Other' && (
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="other_type"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('events_page.other_type')}
                            />
                            <TextInput
                              focus={false}
                              inputType="text"
                              inputName="other_type"
                              inputId="other_type"
                              hint={(formState?.type === 'Other') ? t('events_page.enter_other_event_type') : "NA"}
                              isRequired={false}
                              isDisabled={false}
                              inputStyle={(formState?.type === 'Other') ? textInputStyle.current.enabledInput : textInputStyle.current.disabledInput}
                              inputValue={formState?.other_type}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.other_type)}
                          </div>
                        )}

                        {/* Medicine - text */}
                        {(formState?.type !== 'Sold') && 
                          (formState?.type !== 'Died') && 
                          (formState?.type !== 'Dried') && (
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="medicine"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('events_page.medicine')}
                            />
                            <TextInput
                              focus={false}
                              inputType="text"
                              inputName="medicine"
                              inputId="medicine"
                              hint={t('events_page.enter_medicine_used')}
                              isRequired={false}
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.medicine}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.medicine)}
                          </div>
                        )}  
                        
                        {(formState?.type !== 'Sold') && 
                          (formState?.type !== 'Castration') && 
                          (formState?.type !== 'Culled') && 
                          (formState?.type !== 'Calved') && 
                          (formState?.type !== 'Heat') && 
                          (formState?.type !== 'Prostaglandin') && 
                          (formState?.type !== 'Serviced') && 
                          (formState?.type !== 'Dried') && 
                          (formState?.type !== 'De-horned') && 
                          (formState?.type !== 'Died') && (
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">                                               
                            {/* Repeat Event Date - date */}
                            <InputLabel
                              inputId="repeat_date"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('events_page.enter_repeat_date')}
                            />
                            <TextInput
                              focus={false}
                              inputType="date"
                              inputName="repeat_date"
                              inputId="repeat_date"
                              isRequired = {false}
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.repeat_date}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.repeat_date)}

                            {/* Treatment - text */}
                            <InputLabel
                              inputId="treatment"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('events_page.treatment')}
                            />
                            <TextInput
                              focus={false}
                              inputType="text"
                              inputName="treatment"
                              inputId="treatment"
                              hint={t('events_page.enter_treatment')}
                              isRequired={false}
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.treatment}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.treatment)}
                          </div>
                        )}

                        {/* Notes - textarea */}
                        <div className="col-span-6">
                          <InputLabel
                            inputId="notes"
                            inputStyle="block text-sm font-medium text-gray-700"
                            labelText={t('page_elements.notes')}
                          />
                          <TextareaInput
                            focus={false}
                            inputName="notes"
                            inputId="notes"
                            hint={t('page_elements.enter_notes_about_cattle')} 
                            isRequired={false}
                            inputRows={3}
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.notes}
                            handleChange={handleChange}
                          />
                          {showFieldErrors(responseErrors.notes)}
                        </div>
                    </div>
                  </div>
                  <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />
                </div>
              </form>
            </div>
          </div>
      </div>
    );

}
import { TextInput, InputLabel, TelInput, TimeInput, TextareaInput, Footer, EditDelTableDataView } from '../components'
import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { createResource, getResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const VisitForm = () => {

    // for language translation
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { user } = useUser();  // Get currently loggedIn user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const { publish } = useEvents(); // publish - dispatches events
    const { handleGetResource, handleSubmit } = useHandleSubmit(); // Shared submit handler
    const currentDateTime = new Date().toISOString(); // Crrent date and time
    const today = currentDateTime.split('T')[0]; // Current date
    const currentTime = currentDateTime.split('T')[1].split(':').splice(0,2).join(':'); // Current time

    const location = useLocation();
    const action = location.state?.action;
    const [visitingViewData, setVisitingViewData] = useState([]);
    const [selectedVisit, setSelectedVisit] = useState([]);
    
    // Foot Type; nav -> default, editDelete -> to show edit and delete buttons
    const [footerType, setFooterType] = useState("nav");
    // Influences how table is displayed
    const [tableSettings, setTableSettings] = useState({
        idUsage: 1,
        useSerial: true,
    });

    useEffect(() => {
        const getVisitingReport = async (reportQuery) => {
            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_VISITS + reportQuery, 
                    setCreateNotice,
                    null,
                    setVisitingViewData,
                    null,
                    false // explicitly expecting a single model?
                );
            } catch (error) {
                console.error("Error fetching visiting report data:", error);
            }
        };

        // Make any required changes
        const idUsage = 1;
        const useSerial = true;
        setTableSettings({ idUsage, useSerial });
        setFooterType("edit");       

        // console.log('check if useEffect triggers with action: ', action);
    
        // Fetch data and update reportFarmData
        const filter = 'leaving'; // used to filter report on URL API
        const reportQuery = "/?filter=" + filter;
        if (action === 'Leaving') {
            // will call request for visit report only if Leaving is selected
            getVisitingReport(reportQuery);
        }

    }, []);

    // ID for the currently selected record in Edit and Delete table
    const [selectedId, setSelectedId] = useState(0);
    // Handle Radio's input record selection on Edit and Delete table
    const handleOnSelectId = (inputKey, inputValue) => {
        // Check if the radio button is clicked
        if (inputKey === 'selectedId') {
        } else {
            // When the row is clicked, swap inputKey and inputValue
            inputValue = inputKey;
            inputKey = 'selectedId';
        }

        // Always set inputKey to 'selectedId'
        inputKey = 'selectedId';

        setSelectedId(inputValue);
    };

    const handleCancel = (e) => {
        e.preventDefault();

        // return to the MainMenu page
        navigate('/fe');
    };
    
    // Fetch a record to edit
    const handleUpdate = (e) => {
        e.preventDefault();

        if (selectedId) {

            const visitValues = visitingViewData.find(visit => visit.id === selectedId);
            setSelectedVisit ( visitValues );

            // If the selected visit is found, log its details
            if (selectedVisit) {

                    // Navigate to '/fe/visits' and pass the action and selectedVisit as state
                    navigate('/fe/visits', { state: { action: 'ExitTime', selectedVisit } });

            } else {
                    // console.log("Visit not found with ID:", selectedId);
            }
        } else {
            // inform user that nothing has been selected
            // info, error, success are possible options
            showToast(t("validation_messages.select_record_first"), "info");  
        }
    };

    const updateVisiterExitTime = async (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();
        
        const filter = 'exit';
        const reportQuery = `/?filter=${filter}`;

        const queryParams = {
            selectedId: selectedId,
            time_out: formState.time_out
        };

        const queryParamsString = new URLSearchParams(queryParams).toString();
        const finalQuery = `${reportQuery}&${queryParamsString}`;

        try {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_VISITS + finalQuery, // Set the correct API URL
                setCreateNotice,
                null,
                () => {
                    // This callback is executed after handleGetResource completes
                    const refreshFilter = 'leaving'; // used to filter report on URL API
                    const refreshReportQuery = "/?filter=" + refreshFilter;
    
                    // Refresh visiting report after updating exit time
                    refreshVisitingReport(refreshReportQuery)
                        .then(() => {
                            // Navigate to the "Leaving" page after the visiting data has been updated
                            navigate('/fe/visits', { state: { action: 'Leaving' } });
                        })
                        .catch(error => {
                            console.error("Error refreshing visiting report data:", error);
                        });
                },
                null,
                false // explicitly expecting a single model?
            );

        } catch (error) {
            console.error("Error updating visitor report data:", error);
        }
    };

    const refreshVisitingReport = async (reportQuery) => {
        return new Promise((resolve, reject) => {
            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_VISITS + reportQuery, // Set the correct API URL
                    setCreateNotice,
                    null,
                    setVisitingViewData,
                    null,
                    false // explicitly expecting a single model?
                );
                resolve();
            } catch (error) {
                console.error("Error fetching visiting report data:", error);
                reject(error);
            }
        });
    };

    const showToast = (message, type) => {
        toast[type](message, {
          position: "top-center", 
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    };
    // --------------------- start deciding how to handle queued record if any -------------------
        const [queuedRecord, setFarmRecord] = useState(null);
        const [initFormState, setInitFormState] = useState(null);

        useEffect(() => {
            // Retrieve record from local storage using the special key if was a failed queue
            const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
            if (queuedFarmRecord) {
                const parsedFarmRecord = JSON.parse(queuedFarmRecord);
                setFarmRecord(parsedFarmRecord);

                // Delete the special key and its corresponding data from local storage
                localStorage.removeItem('queuedFarmRecord');
            }
        }, []);


        useEffect(() => {
            if (queuedRecord) {
                // If queueRecord exists, initialize initFormState with its data
                setInitFormState({ ...queuedRecord, user_id: user.id });

            } else {
                // If queueRecord is null, initialize initFormState with default values
                setInitFormState({
                    date: today,
                    name: '',
                    phone: '',
                    time_in: currentTime,
                    time_out: '00:00',
                    selectedId: '',
                    reason: '',
                    vehicle_no: '',
                    user_id: user.id,
                });
            }
        }, [queuedRecord]);

        // ensuring initFormState is initialized before rendering a page
        useEffect(() => {
                if (initFormState !== null) {
                    setFormState(initFormState);
                }
        }, [initFormState]);

        const resetFormState = () => { 
            setFormState(initFormState);
            setFarmRecord(null);
        }

        const [formState, setFormState] = useState(initFormState);
    // --------------------- end deciding how to handle queued record if any -------------------
    
    // const [formState, setFormState] = useState(initFormState);
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState('');

    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_visits'), backPath: "", homePath: "/fe"}));
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
       // eslint-disable-next-line
     }, []);

    const handleChange = (inputKey, inputValue) => {
        setFormState({...formState, [inputKey]: inputValue});
    }
    
    if (initFormState === null) {
        return <div>{t('error_message.loading')}</div>; // or any loading indicator
    }

    return (
            <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
                <div className="mt-5 md:col-span-2 md:mt-0">
                    {action === 'Entering' && (
                        <form
                            onSubmit={(e) => {
                                handleSubmit(
                                    e,
                                    formState,
                                    createResource,
                                    envState.online,
                                    process.env.REACT_APP_VISITS,
                                    setResponseErrors,
                                    setCreateNotice,
                                    resetFormState,
                                    publish
                                )
                            }}
                            autoComplete="off"
                        >
                            <div className="overflow-hidden sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    {/* Date - date */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="date"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.visit_date')}
                                        />
                                        <TextInput
                                            focus={false}
                                            inputType="date"
                                            inputName="date"
                                            inputId="date"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.date}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.date)}
                                    </div>
                                    {/* Name - text */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="name"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.full_name')}
                                        />
                                        <TextInput
                                            focus={false}
                                            inputType="text"
                                            inputName="name"
                                            inputId="name"
                                            hint={t('visits_page.visitor_full_name')}
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.name}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.name)}
                                    </div>
                                    {/* Phone - tel */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="phone"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.phone')}
                                        />
                                        <TelInput
                                            focus={false}
                                            inputName="phone"
                                            inputId="phone"
                                            hint={t('visits_page.visitor_phone_number')}
                                            inputPattern="^(\+|0)\d{9,14}$"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.phone}
                                            handleChange={handleChange}
                                        /> 
                                        {showFieldErrors(responseErrors.phone)}
                                    </div>
                                    {/* TimeIn - time */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="time_in"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.time_in')}
                                        />
                                        <TimeInput
                                            focus={false}
                                            inputName="time_in"
                                            inputId="time_in"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.time_in}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.time_in)}
                                    </div>

                                    {/* TimeOut - time */}
                                    {action !== 'Entering' && (
                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <InputLabel
                                                inputId="time_out"
                                                inputStyle="block text-sm font-medium text-gray-700"
                                                labelText={t('visits_page.time_out')}
                                            />
                                            <TimeInput
                                                focus={false}
                                                inputName="time_out"
                                                inputId="time_out"
                                                inputStyle={textInputStyle.current.enabledInput}
                                                inputValue={formState.time_out}
                                                handleChange={handleChange}
                                            />
                                            {showFieldErrors(responseErrors.time_out)}
                                        </div>
                                    )}

                                    {/* VehicleNo - text */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="vehicle_no"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.vehicle_number')}
                                        />
                                        <TextInput
                                            focus={false}
                                            inputType="text"
                                            inputName="vehicle_no"
                                            inputId="vehicle_no"
                                            hint={t('visits_page.enter_vehicle_number')}
                                            isRequired={false}
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.vehicle_no}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.vehicle_no)}
                                    </div>
                                    {/* Reason - textarea */}
                                    <div className="col-span-6">
                                        <InputLabel
                                            inputId="reason"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.reason')}
                                        />
                                        <TextareaInput
                                            focus={false}
                                            inputName="reason"
                                            inputId="reason"
                                            hint={t('visits_page.enter_visit_reason')}
                                            inputRows={3}
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.reason}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.reason)}
                                    </div>
                                </div>
                            </div>
                            <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />
                            </div>
                        </form>
                    )}  
                    {action === 'Leaving' && (
                        <form autoComplete="off" className="w-full">
                            <div className="col-span-6 lg:col-span-3">
                                <div className="w-full flex justify-center p-2">
                                    {visitingViewData !== null ? ( 
                                        visitingViewData.length > 0 ? (
                                            <EditDelTableDataView
                                                tableData={visitingViewData}
                                                idUsage={0}
                                                useSerial={tableSettings.useSerial}
                                                mapHash={{ // TDOO: translate these
                                                    "#": "#",
                                                    date: t("visits_page.visit_date"),
                                                    name: t("visits_page.full_name"),
                                                    phone: t("visits_page.phone"),
                                                    time_in: t("visits_page.time_in"),
                                                    vehicle_no: t("visits_page.vehicle_number"),
                                                }}
                                                selectedId={selectedId}
                                                onSelectId={handleOnSelectId}
                                            />
                                        ) : (
                                            <div>{t("visits_page.checked_out")}</div> 
                                        )
                                    ) : (
                                        <div>{t("error_message.loading")}</div>
                                    )}
                                    <div className="hidden lg:block lg:col-span-1">
                                    {/*spacer*/}
                                    </div>

                                </div>
                            </div>
                            {visitingViewData.length !== 0 && (
                                <Footer
                                    respNotice={createNotice}
                                    setRespNotice={setCreateNotice}
                                    backLink="/fe/reports"
                                    footerType={footerType}
                                    onCancel={handleCancel}
                                    onEdit={handleUpdate}
                                />
                            )}
                        </form>
                    )}
                    {action === 'ExitTime' && (
                        <form onSubmit={updateVisiterExitTime} autoComplete="off" >
                            <div className="overflow-hidden sm:rounded-md">
                            <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    {/* Date - date */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="date"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.visit_date')}
                                        />
                                        <TextInput
                                            focus={false}
                                            inputType="date"
                                            inputName="date"
                                            inputId="date"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={selectedVisit?.date || ''}
                                            disabled={true}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.date)}
                                    </div>
                                    {/* Name - text */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="name"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.full_name')}
                                        />
                                        <TextInput
                                            focus={false}
                                            inputType="text"
                                            inputName="name"
                                            inputId="name"
                                            hint={t('visits_page.visitor_full_name')}
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={selectedVisit?.name || ''}
                                            disabled={true}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.name)}
                                    </div>
                                    {/* Phone - tel */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="phone"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.phone')}
                                        />
                                        <TelInput
                                            focus={false}
                                            inputName="phone"
                                            inputId="phone"
                                            hint={t('visits_page.visitor_phone_number')}
                                            inputPattern="^(\+|0)\d{9,14}$"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={selectedVisit?.phone || ''}
                                            disabled={true}
                                            handleChange={handleChange}
                                        /> 
                                        {showFieldErrors(responseErrors.phone)}
                                    </div>
                                    {/* TimeIn - time */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="time_in"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.time_in')}
                                        />
                                        <TimeInput
                                            focus={false}
                                            inputName="time_in"
                                            inputId="time_in"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={selectedVisit?.time_in || ''}
                                            disabled={true}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.time_in)}
                                    </div>

                                    {/* TimeOut - time */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="time_out"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.time_out')} 
                                        />
                                        <TimeInput
                                            focus={false}
                                            inputName="time_out"
                                            inputId="time_out"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState.time_out}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.time_out)}
                                    </div>

                                    {/* VehicleNo - text */}
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                        <InputLabel
                                            inputId="vehicle_no"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText={t('visits_page.vehicle_number')}
                                        />
                                        <TextInput
                                            focus={false}
                                            inputType="text"
                                            inputName="vehicle_no"
                                            inputId="vehicle_no"
                                            hint={t('visits_page.enter_vehicle_number')}
                                            isRequired={false}
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={selectedVisit?.vehicle_no || ''}
                                            disabled={true}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.vehicle_no)}
                                    </div>
                                </div>
                            </div>
                            <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />
                            </div>
                        </form>
                    )} 
                </div>
                <div>
                    {/* Toast Container */}
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick
                        pauseOnHover
                        draggable
                        pauseOnFocusLoss
                        theme="light"
                    />
                </div>   
            </div>
    );

}
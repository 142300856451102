import { UserProvider, EnvProvider } from './contexts';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  Header,
  Login,
  ProtectRoute, 
  MainMenu,
  ManageMenu, 
  ReportsMenu,
  SharedEmpty,
 } from './components/';

import {
  CattleForm,
  NotFound,
  UserForm,
  EventForm,
  MilkingForm,
  DipForm,
  VisitForm,
  WashForm,
  WeighingForm,
  CattleView,
  CattleReport,
  PregnancyReport,
  EventsReport,
  MilkingsReport,
  UsersReport,
  VisitsReport,
  WeighingsReport,
  // LanguageForm,
  AboutUs,
  AppManual,
  TermsAndConditions,
  DairyFarming,
  Disclaimer,
  Share,
  Feedback,
  FaqBoard,
  TransactionsForm,
  ManageProfileForm,
  GdfAiForm,
  MarketPlaceForm,
  SaleForm, 
  ProductDetail, 
  RequestDetail,
  SaleBrowseForm,
  RequestBuyForm,
  CloseDealForm,
  SupplyForm,
  OpenBoardForm
} from './pages';

function App() {

  return (
    
    <EnvProvider>
      <UserProvider>
        <BrowserRouter>
            <Routes>
              <Route path='fe/login' element={<Login />} />
              <Route path='fe' element={<ProtectRoute><Header /></ProtectRoute>}>
                <Route index element={<ProtectRoute><MainMenu /></ProtectRoute>} />
                <Route path='manage' element={<ProtectRoute><ManageMenu /></ProtectRoute>} />
                <Route path='cattle' element={<ProtectRoute><CattleForm /></ProtectRoute>} />
                <Route path='users' element={<ProtectRoute><UserForm /></ProtectRoute>} />
                <Route path='events' element={<ProtectRoute><EventForm /></ProtectRoute>} />
                <Route path='milkings' element={<ProtectRoute><MilkingForm /></ProtectRoute>} />
                <Route path='dips' element={<ProtectRoute><DipForm /></ProtectRoute>} />
                <Route path='visits' element={<ProtectRoute><VisitForm /></ProtectRoute>} />
                <Route path='washes' element={<ProtectRoute><WashForm /></ProtectRoute>} />
                <Route path='weighings' element={<ProtectRoute><WeighingForm /></ProtectRoute>} />
                <Route path='aboutus' element={<ProtectRoute><AboutUs /></ProtectRoute>} />
                <Route path='appmanual' element={<ProtectRoute><AppManual /></ProtectRoute>} />
                <Route path='termsandconditions' element={<ProtectRoute><TermsAndConditions /></ProtectRoute>} />
                <Route path='dairyfarming' element={<ProtectRoute><DairyFarming /></ProtectRoute>} />
                <Route path='faq' element={<ProtectRoute><FaqBoard /></ProtectRoute>} />
                <Route path='disclaimer' element={<ProtectRoute><Disclaimer /></ProtectRoute>} />
                <Route path='share' element={<ProtectRoute><Share /></ProtectRoute>} />
                <Route path='feedback' element={<ProtectRoute><Feedback /></ProtectRoute>} />
                <Route path='transactions' element={<ProtectRoute><TransactionsForm /></ProtectRoute>} />
                <Route path='manageprofile' element={<ProtectRoute><ManageProfileForm /></ProtectRoute>} />
                <Route path='gdfai' element={<ProtectRoute><GdfAiForm /></ProtectRoute>} />
                
                {/* Define the route for the marketplace */}
                <Route path="marketplace" element={<MarketPlaceForm />}>
                  {/* Nested routes within the marketplace */}
                  <Route index element={<SaleBrowseForm />} /> {/* Default component */}
                  <Route path="product-list" element={<SaleBrowseForm />} />
                  <Route path="add-product" element={<SaleForm />} />
                  <Route path="request-buy" element={<RequestBuyForm />} /> 
                  <Route path="close-deal" element={<CloseDealForm />} /> 
                  <Route path="open-board" element={<OpenBoardForm />} /> 
                  <Route path="supply-item" element={<SupplyForm />} />
                  <Route path="product-detail/:productId" element={<ProductDetail />} />
                  <Route path="request-detail/:productId" element={<RequestDetail />} />
                </Route>

                <Route path='reports' element={<ProtectRoute><SharedEmpty /></ProtectRoute>}>
                  <Route index element={<ProtectRoute><ReportsMenu /></ProtectRoute>} />
                  <Route path='search' element={<ProtectRoute><CattleView /></ProtectRoute>} />
                  <Route path='cattle' element={<ProtectRoute><CattleReport /></ProtectRoute>} />
                  <Route path='pregnancy' element={<ProtectRoute><PregnancyReport /></ProtectRoute>} />
                  <Route path='milkings' element={<ProtectRoute><MilkingsReport /></ProtectRoute>} />
                  <Route path='users' element={<ProtectRoute><UsersReport /></ProtectRoute>} />
                  <Route path='events' element={<ProtectRoute><EventsReport /></ProtectRoute>} />
                  <Route path='visits' element={<ProtectRoute><VisitsReport /></ProtectRoute>} />
                  <Route path='weighings' element={<ProtectRoute><WeighingsReport /></ProtectRoute>} />
                </Route>
                <Route path='*' element={<ProtectRoute><NotFound /></ProtectRoute>} />
              </Route>
            </Routes>
        </BrowserRouter>
      </UserProvider>
    </EnvProvider>
  );
}

export default App;

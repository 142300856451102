import { MenuItem, DummyMenuItem } from "./index";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { ArrowLongRightIcon } from "../icons";
import { useState, useEffect } from "react";
import { useA2HS } from './a2hsContext';
import { useUser } from "../contexts";
import Swal from 'sweetalert2';


import {
    CalendarDaysIcon,
    MilkBottleOutlineIcon,
    FemaleCattleIcon,
    TasksOpenIcon,
    DataBoardIcon,
    IdentificationIcon,
    StopIcon,
    CubeTransparent,
    BackUpButton,
} from "../icons";

const MainMenu = () => {
    const { user } = useUser();

    // for language translation
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { deferredPrompt, setDeferredPrompt, showInstallPrompt, setShowInstallPrompt } = useA2HS();

    // Function to handle showing the Swal prompt and updating state based on user choice
    const handleShowSwal = () => {
        Swal.fire({
            title: t("validation_messages.swa_visitor_title"),
            text: t("validation_messages.swa_visitor_text"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("validation_messages.swa_entering_button"),
            cancelButtonText: t("validation_messages.swa_leaving_button"),
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.isConfirmed) {
                // If user clicks Entering, show the MenuItem
                navigate('/fe/visits', { state: { action: 'Entering' } });
            } else {
                // If user clicks Leaving, do nothing or handle accordingly
                navigate('/fe/visits', { state: { action: 'Leaving' } });
            }
        });
    };

    // Add to Home Screen using pop using Swal
    const handleAddToHomeScreen = async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          const choiceResult = await deferredPrompt.userChoice;
          if (choiceResult.outcome === 'accepted') {
            // console.log('User accepted the A2HS prompt');
          } else {
            // console.log('User dismissed the A2HS prompt');
          }
          setDeferredPrompt(null);
          setShowInstallPrompt(false);
        }else{
            // console.log('Installed already and deferredPrompt is false');
        }
      };
    
      useEffect(() => {
        // Show the button 5 seconds after the component is mounted
        const timer = setTimeout(() => {
          setShowInstallPrompt(true);
        }, 5000);
    
        return () => clearTimeout(timer);
      }, [setShowInstallPrompt]);



    return(
        <div className="mt-[100px] mb-0 p-2 md:p-0">
            <div className="grid grid-cols-6">
                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div className="col-span-6 flex flex-col gap-y-4">
                    {/** Menu Item rows */}
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/cattle" itemTitle={t('buttons_navigation.button_nav_cattle')}>
                                <FemaleCattleIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('page_elements.tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                        <MenuItem urlSegment="/fe/milkings" itemTitle={t('buttons_navigation.button_nav_milking')}>
                            <MilkBottleOutlineIcon />
                        </MenuItem>
                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        <MenuItem urlSegment="/fe/events" itemTitle={t('buttons_navigation.button_nav_events')}>
                            <CalendarDaysIcon />
                        </MenuItem>

                        {/* <MenuItem urlSegment="/fe/visits" itemTitle={t('buttons_navigation.button_nav_visits')}>
                             <IdentificationIcon />
                            </MenuItem> */}

                        {/* Render a div with onClick event handler to show the Swal prompt */}
                        <div onClick={handleShowSwal}>
                            <MenuItem itemTitle={t('buttons_navigation.button_nav_visits')}>
                                <IdentificationIcon />
                            </MenuItem>
                        </div>

                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        <MenuItem urlSegment="/fe/manage" itemTitle={t('buttons_navigation.button_nav_manage')}>
                            <TasksOpenIcon />
                        </MenuItem>
                        <MenuItem urlSegment="/fe/reports" itemTitle={t('buttons_navigation.button_nav_reports')}>
                            <DataBoardIcon />
                        </MenuItem>
                    </div>

                    {/** adding GDF AI and spare button */}
                    <div className="flex justify-center items-center gap-x-3">

                        <MenuItem urlSegment="/fe/gdfai" itemTitle={t('buttons_navigation.button_ai_gdf_chat')}> 
                            <CubeTransparent />
                        </MenuItem>

                 
                        {/* Render a div with onClick event handler to show the Swal prompt */}
                        <MenuItem urlSegment="" itemTitle="TBC">
                            <BackUpButton />
                        </MenuItem>

                        {/**  // TODO: this is for Marketplan opening a home place for it */}
                        {/* <MenuItem urlSegment="/fe/marketplace" itemTitle={t('market_place_page.market_place')}> 
                            <BackUpButton /> 
                        </MenuItem> */}
                    </div>

                    {showInstallPrompt && deferredPrompt && (
                        <div className="pt-0">
                            <div className="text-gray-600  text-s p-0">
                                {t('user_handle_notice.a2hs_notice')}
                            </div>
                            <button
                                type="button"
                                onClick={handleAddToHomeScreen}
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                            >
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <ArrowLongRightIcon
                                        className="h-5 w-5 text-white group-hover:text-indigo-400"
                                        aria-hidden="true"
                                    />
                                </span>
                                {t('user_handle_notice.a2hs_add_home_screen_button')}
                            </button>
                        </div>
                    )}

                    <div className="flex flex-col justify-center items-center mt-2 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                        <span className="whitespace-nowrap">{t('home_page.courtesy')}</span>
                        <span className="whitespace-nowrap">{t('home_page.web')}</span>
                        <span className="whitespace-nowrap">{t('home_page.email')}</span>
                        <span className="whitespace-nowrap">{t('home_page.copyright')}</span>
                    </div>

                    {/* <div className="flex flex-col justify-center items-center mt-2 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                        <span>{t('home_page.courtesy')}</span>
                        <span>{t('home_page.web')}</span>
                        <span>{t('home_page.email')}</span>
                        <span>{t('home_page.copyright')}</span>
                    </div> */}
                </div>
                {/** Left spacer */}
                <div className="col-span-1"></div>
            </div>
        </div>
    );
}

export { MainMenu };
import { useGirthLength, useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { TextInput, InputLabel, SelectInput, NumberInput, Footer } from '../components';
import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { createResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';

export const WeighingForm = () => {

  // for language translation
  const { t } = useTranslation();

  const { user } = useUser();  // Get currently loggedIn user
  const { envState, setEnvState } = useEnv(); // Get Environment context
  const { publish } = useEvents(); // publish - dispatches events
  const { handleSubmit } = useHandleSubmit(); // Shared submit handler
  const today = new Date().toISOString().split('T')[0]; // Todays' date
// --------------------- start deciding how to handle queued record if any -------------------

const [queuedRecord, setFarmRecord] = useState(null);
const [initFormState, setInitFormState] = useState(null);

useEffect(() => {
    // Retrieve record from local storage using the special key if was a failed queue
    const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
    if (queuedFarmRecord) {
        const parsedFarmRecord = JSON.parse(queuedFarmRecord);
        setFarmRecord(parsedFarmRecord);

        // Delete the special key and its corresponding data from local storage
        localStorage.removeItem('queuedFarmRecord');
    }
}, []);


useEffect(() => {
    if (queuedRecord) {
        // If queueRecord exists, initialize initFormState with its data
        setInitFormState({ ...queuedRecord, user_id: user.id });

    } else {
        // If queueRecord is null, initialize initFormState with default values
        setInitFormState({
          cattle_id: '',
          date: today,
          girth_length: 68,
          weight: 30,
          user_id: user.id,
        });
    }
}, [queuedRecord]);

// ensuring initFormState is initialized before rendering a page
useEffect(() => {
      if (initFormState !== null) {
        setFormState(initFormState);
      }
}, [initFormState]);

const resetFormState = () => { 
  setFormState(initFormState);
  setFarmRecord(null);
}

const [formState, setFormState] = useState(initFormState);

// --------------------- end deciding how to handle queued record if any -------------------

  
  
  // // Initial Form state
  //   const initFormState = {
  //       cattle_id: '',
  //       date: today,
  //       girth_length: 68,
  //       weight: 30,
  //       user_id: user.id,
  //   };

  //   const [formState, setFormState] = useState(initFormState);
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState('');

    const textInputStyle = useEnaDisableStyle();
    // Takes in array off validation errors and returns jsx.
    const showFieldErrors = useErrorParser();
    // Retrieve the Girth chart
    const girthRef = useRef(useGirthLength());

    // Update weight value before painting the DOM
    useLayoutEffect(() => {
        if (formState?.girth_length >= girthRef.current[0] && formState?.girth_length <= girthRef.current[1]) {
          if (girthRef.current[2].has(formState?.girth_length)) {
            setFormState(state => ({...state, weight: girthRef.current[2].get(state.girth_length)}));
          } else {
            setFormState(state => ({...state, weight: 0}));
          }
        } else {
          setFormState(state => ({...state, weight: 0}));
        }
      },
      [formState?.girth_length]
    );

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_weigh'), backPath: "/fe/manage", homePath: "/fe"}));
      return () => {
        setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []);
  
    const handleChange = (inputKey, inputValue) => {
        setFormState({...formState, [inputKey]: inputValue});
    }

    if (initFormState === null) {
      // : see how you get this properly
      return <div>{t("error_message.loading")}</div>; // or any loading indicator
    }

    // Custom handle submit, i.e vs generic, so as to handle local validations.
    const localHandleSubmit = (e) => {
      if (formState?.measurement_type === 'Girth'){
        if (formState?.girth_length > 277 || formState?.girth_length < 58) {
          e.preventDefault();
          
          setResponseErrors({girth_length: t('error_message.girth_length_out_of_range')})
        }else {
          setResponseErrors({});
          handleSubmit(
            e,
            formState,
            createResource,
            envState.online,
            process.env.REACT_APP_WEIGHINGS,
            setResponseErrors,
            setCreateNotice,
            resetFormState,
            publish
          )
        }
      }else {
        setResponseErrors({});
        handleSubmit(
          e,
          formState,
          createResource,
          envState.online,
          process.env.REACT_APP_WEIGHINGS,
          setResponseErrors,
          setCreateNotice,
          resetFormState,
          publish
        )
      }
    }

    return (
        <div className="mt-[100px] mb-[100px]">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={localHandleSubmit} autoComplete="off">
                  <div className="overflow-hidden sm:rounded-md">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                          {/* Cattle Id field - text*/}
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="cattle_id"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('page_elements.tag')}
                            />
                            <TextInput
                              focus={true}
                              inputType="text"
                              inputName="cattle_id"
                              inputId="cattle_id"
                              hint={t('page_elements.enter_tag')}
                              inputPattern="^[a-zA-Z0-9]{1,10}$"
                              isAutoComplete="on"
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.cattle_id}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.cattle_id)}
                          </div>
                          {/* Weighing Date - date */}
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                              inputId="date"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('table_formatter.table_date')}
                            />
                            <TextInput
                              focus={false}
                              inputType="date"
                              inputName="date"
                              inputId="date"
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.date}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.date)}
                          </div>
                              {/* Select weight measurement type i.e girth length or weight type */}
                              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <InputLabel
                                  inputId="measurement_type"
                                  inputStyle="block text-sm font-medium text-gray-700 text-left"
                                  labelText="Select measurement type"
                                />
                                <SelectInput
                                    focus={false}
                                    inputName="measurement_type"
                                    inputId="measurement_type"
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState?.income_type}
                                    handleChange={handleChange}
                                    choices={[
                                      {key: 'Select', label: "Select measure type" }, 
                                      {key: 'Girth', label: "Girth Length" }, 
                                      {key: 'Weight', label: "Weight in Kg" },  
                                    ]}
                                  />
                                <div className="response-error-message">
                                  {showFieldErrors(responseErrors.income_type)}
                                </div>
                              </div>

                              {/** the following part should only be shown whem weight is selected from the drop down */}
                              {formState?.measurement_type === 'Weight' && (
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="weight"
                                    inputStyle="block text-sm font-medium text-gray-700 text-left"
                                    labelText="Input Weight in Kg"
                                  />
                                  <NumberInput
                                    focus={false}
                                    inputName="weight"
                                    inputId="weight"
                                    hint="0"
                                    isRequired={false}
                                    isDisabled={false}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState?.weight}
                                    inputStep="0.01"
                                    handleChange={setFormState}
                                  />                              
                                  <div className="response-error-message">
                                    {showFieldErrors(responseErrors.quantity)}
                                  </div>
                                </div>
                              )}

                              {/* Girth Length - Number */}
                              {formState?.measurement_type === 'Girth' && (
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="girth_length"
                                    inputStyle="block text-sm font-medium text-gray-700"
                                    labelText={t('table_formatter.table_girth_length')}
                                  />
                                  <NumberInput
                                    focus={false}
                                    inputName="girth_length"
                                    inputId="girth_length"
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState?.girth_length}
                                    min={58}
                                    handleChange={setFormState}
                                  />
                                  {showFieldErrors(responseErrors.girth_length)}


                                  <InputLabel
                                    inputId="weight"
                                    inputStyle="block text-sm font-medium text-gray-700"
                                    labelText={t('table_formatter.table_weight')}
                                  />
                                  <NumberInput
                                    focus={false}
                                    inputName="weight"
                                    inputId="weight"
                                    isDisabled={true}
                                    inputStyle={textInputStyle.current.disabledInput}
                                    inputValue={formState?.weight}
                                    handleChange={setFormState}
                                  />
                                  {showFieldErrors(responseErrors.weight)}
                              </div>
                     
                              )}
                              {/** the end of of the part that is measurement type from the drop down */}
                      </div>
                    </div>
                    <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />
                  </div>
              </form>
            </div>
          </div>
        </div>
    );


}
import { useState, useRef } from "react";

import { guestCreateResource } from "../../services/farmApi";

import { ArrowLongLeftIcon, LockSolidOpenedIcon } from "../../icons";
import logo from "../../icons/gdf192.png";
import { Spinner } from "../Spinner";

import { useTranslation } from "react-i18next";

import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'

// Register Dialog
const Register = ({ onChangeDialog }) => {
  const [newFarm, setNewFarm] = useState({
    email: "",
    farmName: "",
    password: "",
    passwordPair: "",
  });
  const [regError, setRegError] = useState("");
  const [revealResp, setRevealResp] = useState("");
  const [shouldSpin, setShouldSpin] = useState(false);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const repasswordInputRef = useRef(null);

  const [password, setPassword] = useState("");
  const [passwordPair, setPasswordPair] = useState("");
  const [type, setType] = useState('password');
  const [typePair, setTypePair] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [iconPair, setIconPair] = useState(eyeOff);

  const handleRegister = async (e) => {
    e.preventDefault();

    if (newFarm.password !== newFarm.passwordPair) {
      //setRegError("Typed passwords do not match");
      setRegError(t('error_message.mismatch_password'))
    } else {
      // Fire spinner
      setShouldSpin(true);
      const regResp = await guestCreateResource(
        process.env.REACT_APP_FARM_REGISTER,
        {
          farm_name: newFarm.farmName,
          email: newFarm.email,
          password: newFarm.password,
        },
        t
      );
      if (regResp.success) {
        setRegError("");
        // Stop spinner
        setShouldSpin(false);
        // Show Notificaton screen
        setRevealResp(regResp.message);
      } else {
        // Show error on register dialog
        // if (regResp.status === 101) {
        //   setRegError("Wrong Email or Password");
        // } else {
        //   setRegError("Login failed. Try again later.");
        // }
        setRegError(regResp?.message);
        // Stop spinner
        setShouldSpin(false);
      }
    }
  };

  const handleFarmName = (e) => {
    setNewFarm((farm) => ({ ...farm, farmName: e.target.value }));
  };

  const handleEmail = (e) => {
    setNewFarm((farm) => ({ ...farm, email: e.target.value }));
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setNewFarm((farm) => ({ ...farm, password: e.target.value }));
  };

  const handlePasswordPair = (e) => {
    setPasswordPair(e.target.value);
    setNewFarm((farm) => ({ ...farm, passwordPair: e.target.value }));
  };

  // Having tricked Chrome browser with readonly email field,
  // so that Chrome disables form input autocompletes, we need to
  // to remove readonly onfocus
  const handleOnFocus = (refType) => {
    if (refType === "email") {
      emailInputRef.current.removeAttribute("readonly");
    } else if (refType === "password") {
      passwordInputRef.current.removeAttribute("readonly");
    } else if (refType === "repassword") {
      repasswordInputRef.current.removeAttribute("readonly");
    }
  };

  // Activate Register dialog
  const handleLogin = (e) => {
    onChangeDialog("landing");
  };

  // for language translation
  const { t } = useTranslation();

  const handleToggle = () => {
    if (type === 'password') {
        setIcon(eye);
        setType('text');
        // Ensure the other input is in password mode and has the eyeOff icon
        if (typePair !== 'text') {
            setTypePair('password');
            setIconPair(eyeOff);
        }
    } else {
        setIcon(eyeOff);
        setType('password');
    }
}

const handleTogglePair = () => {
    if (typePair === 'password') {
        setIconPair(eye);
        setTypePair('text');
        // Ensure the other input is in password mode and has the eyeOff icon
        if (type !== 'text') {
            setType('password');
            setIcon(eyeOff);
        }
    } else {
        setIconPair(eyeOff);
        setTypePair('password');
    }
}

// TODO: to add OTT account create and login in the future
// const pwaAuth = document.querySelector("pwa-auth");
// pwaAuth.addEventListener("signin-completed", ev => {
//     const signIn = ev.detail;
//     if (signIn.error) {
//         console.error("Sign in failed", signIn.error);
//     } else {
//         console.log("Email: ", signIn.email);
//         console.log("Name: ", signIn.name);
//         console.log("Picture: ", signIn.imageUrl);
//         console.log("Provider (MS, Google, FB): ", signIn.provider);
//         console.log("Raw data from provider: ", signIn.providerData);
//     }
// });

  return (
    <>
      {/* nav */}
      <div className="w-full flex justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="flex justify-start pt-2">
            <div
              className="flex gap-2 mt-5 cursor-pointer border border-gray-300 rounded-full pl-2 pr-4 py-1"
              onClick={handleLogin}
            >
              <ArrowLongLeftIcon />
              <span>{t('buttons_navigation.hyperlink_login')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* Header */}
          <div>
            {/* Logo */}
            <div className="flex justify-center">
              <img
                src={logo}
                className="w-[80px] h-auto"
                alt="GDF 512x512 sized logo"
              />
            </div>
            {/* Notification messages - if any */}
            <h2 className="mt-2 text-center text-3xl tracking-tight text-gray-400">
              {regError ? (
                <span className="text-red-400 text-lg">{regError}</span>
              ) : (
                ""
              )}
            </h2>
          </div>

          {/* Show Form, Spin or response */}
          {shouldSpin ? (
            <div className="border rounded-lg p-8 sm:p-2">
              <Spinner />
            </div>
          ) : revealResp !== "" ? (
            <div className="border rounded-lg p-8 sm:p-2">
              <span className="block">{revealResp}</span>
            </div>
          ) : (
            <form className="mt-8 space-y-6" autoComplete="off" method="POST">
              <div className="text-gray-600  text-s p-0">
                {t('login_register_page.register_farm_notice')}
              </div> 
              <input type="hidden" name="remember" defaultValue="true" />
              <div>
                <div className="flex flex-col space-y-4 rounded-md shadow-sm text-lg">
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Farm Name
                    </label>
                    <input
                      id="farmName"
                      name="farmName"
                      type="text"
                      onChange={handleFarmName}
                      autoComplete="off"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                      placeholder={t('login_register_page.enter_farm_name')}
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email address
                    </label>
                    <input
                      ref={emailInputRef}
                      readOnly={true}
                      id="email"
                      name="email"
                      type="email"
                      onChange={handleEmail}
                      onFocus={() => {
                        handleOnFocus("email");
                      }}
                      autoComplete="off"
                      required={true}
                      className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                      placeholder={t('login_register_page.enter_email_address')}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <div className="mb-4 flex">
                      <input
                        ref={passwordInputRef}
                        readOnly={true}
                        id="password"
                        name="password"
                        type={type}
                        onChange={handlePassword}
                        onFocus={() => {
                          handleOnFocus("password");
                        }}
                        autoComplete="off"
                        required={true}
                        className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                        placeholder={t('login_register_page.enter_password')}
                      />
                      <span className="flex justify-around items-center" onClick={handleToggle}>
                          <Icon className="absolute mr-10" icon={icon} size={25}/>
                      </span>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Retype Password
                    </label>
                    <div className="mb-4 flex">
                      <input
                        ref={repasswordInputRef}
                        readOnly={true}
                        id="passwordPair"
                        name="passwordPair"
                        type={typePair}
                        onChange={handlePasswordPair}
                        onFocus={() => {
                          handleOnFocus("repassword");
                        }}
                        autoComplete="off"
                        required={true}
                        className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                        placeholder={t('login_register_page.re_type_password')}
                      />
                      <span className="flex justify-around items-center" onClick={handleTogglePair}>
                          <Icon className="absolute mr-10" icon={iconPair} size={25}/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                {/* Register Button */}
                <div>
                  <button
                    type="submit"
                    onClick={handleRegister}
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockSolidOpenedIcon
                        className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                        aria-hidden="true"
                      />
                    </span>
                    {t('buttons_navigation.button_nav_submit')}
                  </button>
                </div>
              </div>

                    {/** TODO: OTT login to be added in the future versions */}
                    {/* <div> */}
                      {/* <div>Or register using one of these</div> */}
                      {/** <!-- To create a key, see https://github.com/pwa-builder/pwa-auth#creating-keys --> */}
                      {/** as a drop down option */}
                      {/* <pwa-auth
                        microsoftkey="..."
                        googlekey="..."
                        facebookkey="..."
                        applekey="...">
                      </pwa-auth> */}

                      {/** as a list buttons */}
                      {/* <pwa-auth 
                        appearance="list"
                        microsoftkey="..."
                        googlekey="..."
                        facebookkey="..."
                        applekey="...">
                      </pwa-auth> */}

                      {/* <pwa-auth
                        credentialmode="prompt"
                        microsoftkey="..."
                        googlekey="..."
                        facebookkey="..."
                        applekey="...">
                      </pwa-auth> */}
                    {/* </div> */}





            </form>
          )}
        </div>
      </div>
    </>
  );
};

export { Register };

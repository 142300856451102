import React, { useState, useEffect, useRef } from 'react';
import { createResource, getResource } from '../services/farmApi';
import { Footer, EditDelTableDataView } from '../components';
import TabNavItem from "../components/forms/TabNavItem";
import TabContent from "../components/forms/TabContent";
import { toast, ToastContainer } from 'react-toastify';
import { useEvents, useHandleSubmit } from '../hooks';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import "./reports/reportStyle.css";
import "../tabsview.css";
import Swal from 'sweetalert2';

import { 
    TRANS_TYPE_BOARD_BLOCK_CONTACT_ITEMS,
    TRANS_TYPE_BOARD_BLOCK_CONTACT_CATTLE,
    TRANS_TYPE_BOARD_CONTACT_ITEMS,
    TRANS_TYPE_BOARD_CONTACT_CATTLE,
    QUERY_FILTER_BOARD_LIST_CATTLE,
    QUERY_FILTER_BOARD_LIST_ITEMS
 } from '../constants';


const OpenBoardForm = () => {
        // for language translation
        const { t } = useTranslation();
        const { user } = useUser();  // Get currently loggedIn user

        const showToast = (message, type) => {
            toast[type](message, {
            position: "top-center", 
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        };

        const [tableSettings, setTableSettings] = useState({
            idUsage: 1,
            useSerial: true,
        });

        const initNotice = useRef({
            msg: "",
            showNotice: false,
            succ: false,
            status: null,
            ver: null,
        });

        const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
        const [selectedId, setSelectedId] = useState(0);
        const [footerType, setFooterType] = useState("contactPerson");
        const { publish } = useEvents(); // publish - dispatches events
        const { handleSubmit } = useHandleSubmit(); // Shared submit handler
        const [responseErrors, setResponseErrors] = useState({});
        const {envState, setEnvState } = useEnv(); // Get Environment context
        const [boardCattleData, setBoardCattleData] = useState([]);
        const [boardItemsData, setBoardItemsData] = useState([]);
        const [activeTab, setActiveTab] = useState("tab1");
        const [loading, setLoading] = useState(false); // Loading state
        const [errorMessage, setErrorMessage] = useState(null); // Error message state
        const { handleGetResource } = useHandleSubmit(); // Shared submit handler
        const [initFormState, setInitFormState] = useState(null);
        const resetFormState = () => { setFormState(initFormState) }

        // ------------- initialize backend calls --------------------

        // Setting initial form state in useEffect
        useEffect(() => {
            const initFormState = {
                trans_type: '',         // transaction id
                list_id: '',            // advert id
                contact_id: '',         // email address as an identifier of the purchaser or supplier
                auto_inc_id: '',        // auto increment id in supplier or purchaser table
                seller_id: user.email,  // seller or requester identifier 
                user_id: user.id,       // assuming user is defined in the scope
            };
            setInitFormState(initFormState);
            setFormState(initFormState); // Set formState when initFormState is set
        }, []);

        const [formState, setFormState] = useState({
            trans_type: '',         // transaction id
            list_id: '',            // advert id
            contact_id: '',         // email address as an identifier of the purchaser or supplier
            auto_inc_id: '',        // auto increment id in supplier or purchaser table
            seller_id: user.email,  // seller or requester identifier 
            user_id: user.id,       // assuming user is defined in the scope
        });

        useEffect(() => {
            getBoardCattleList(); // default call when screen opens
        }, [envState.online, formState]);

        //-------------- start listing cattle if any ------------------------
        const getBoardCattleList = async () => {
            setLoading(true);  // Start loading

            const reportQueryItem = "/?filter=" + QUERY_FILTER_BOARD_LIST_CATTLE;

            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_MARKET_PLACE + reportQueryItem,
                    setCreateNotice,
                    null,
                    setBoardCattleData, // This will update boardData
                    null,
                    false
                );
                setErrorMessage(null); // Clear any previous errors
            } catch (error) {
                // console.error('Error fetching report data:', error);
                setErrorMessage(t('market_place_page.failed_fetch_data'));
            } finally {
                setLoading(false); // Stop loading
            }
        };

        const formattedCattleData = (boardCattleData.returned_records || []).map(boardCattle => {
            const formattedCattleInterestDate = new Date(boardCattle.interest_date).toISOString().split('T')[0];
            return {
                id: boardCattle.id, // use id to get the list_id after select
                list_id: boardCattle.list_id,
                description: boardCattle.description,
                interest_date: formattedCattleInterestDate,
                // purchaser_id: boardCattle.purchaser_id,
                contact_id: boardCattle.contact_id,
                contacted: boardCattle.contacted === '0' ? 'No' : 'Yes',
                blocked: boardCattle.blocked === '0' ? 'No' : 'Yes',
            };
        });

        //-------------- end listing cattle if any ------------------------

        //-------------- start listing items if any ------------------------
        
        const getBoardItemsList = async () => {
            setLoading(true);  // Start loading

            const reportQueryItem = "/?filter=" + QUERY_FILTER_BOARD_LIST_ITEMS;

            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_MARKET_PLACE + reportQueryItem,
                    setCreateNotice,
                    null,
                    setBoardItemsData, // This will update boardData
                    null,
                    false
                );
                setErrorMessage(null); // Clear any previous errors
            } catch (error) {
                // console.error('Error fetching report data:', error);
                setErrorMessage(t('market_place_page.failed_fetch_data'));
            } finally {
                setLoading(false); // Stop loading
            }
        };

        const formattedItemsData = (boardItemsData.returned_records || []).map(boardItems => {
            const formattedItemsInterestDate = new Date(boardItems.interest_date).toISOString().split('T')[0];
            return {
                id: boardItems.id, // use id to get the list_id after select
                list_id: boardItems.list_id,
                description: boardItems.description,
                interest_date: formattedItemsInterestDate,
                // supplier_id: boardItems.supplier_id,
                contact_id: boardItems.contact_id,
                contacted: boardItems.contacted === '0' ? 'No' : 'Yes',
                blocked: boardItems.blocked === '0' ? 'No' : 'Yes',
            };
        });        

        //-------------- end listing items if any ------------------------


        // ------------ start common effect for cattle items ----------------

        useEffect(() => {
            // console.log('received board cattle data: ', boardCattleData);
            // console.log('received board items data: ', boardItemsData);
        }, [boardCattleData, boardItemsData]);  // This effect runs whenever reportData changes 

        const handleChangeItems = (inputKey, inputValue) => {
            
            // console.log(' what key and value here: ', inputKey + "  " + inputValue);
            resetFormState(); 
            setActiveTab('tab2'); // Default for Cattle tab
            setSelectedId('') // reset id
            setResponseErrors({});

            getBoardItemsList();
        }

        const handleChangeCattle = (inputKey, inputValue) => {

            // console.log(' what key and value here: ', inputKey + "  " + inputValue);
            resetFormState(); 
            setActiveTab('tab1'); // Default for Cattle tab
            setSelectedId('') // reset id
            setResponseErrors({});

            getBoardCattleList();

        }

        const handleOnSelectId = (inputKey, inputValue) => {
            // console.log('handle on input Key and Value select ID: ', inputKey + '  ' + inputValue);
            setSelectedId(inputValue);
        }; 

        // Helper function to populate formState based on active tab and selected record
        // tab1 is for Cattle sales and tab2 is for Items wanting to be supplied
        const populateFormState = (transType, selectedRecord, selectedId) => {
            formState.trans_type = transType;
            formState.list_id = selectedRecord.list_id;
            formState.contact_id = activeTab === 'tab1' ? selectedRecord.purchaser_id : selectedRecord.supplier_id;
            formState.auto_inc_id = selectedId;
            formState.seller_id = user.email;
            formState.user_id = user.id;
        };

        // Handle Contact Person
        const handleContactPerson = async (e) => {
            e.preventDefault();
        
            if (!selectedId) {
                showToast(t("validation_messages.select_record_first"), "error");
                return;
            }
        
            // Determine the data source and transaction type
            const isCattleTab = activeTab === 'tab1';
            const data = isCattleTab ? boardCattleData.returned_records : boardItemsData.returned_records;
            const transType = isCattleTab ? TRANS_TYPE_BOARD_CONTACT_CATTLE : TRANS_TYPE_BOARD_CONTACT_ITEMS;
        
            // Find the selected record by ID
            const selectedRecord = data.find(record => record.id === selectedId);
        
            if (selectedRecord) {
                // Check if the selected record is blocked
                if (selectedRecord.blocked === '1') {
                    showToast(t('market_place_page.cannot_contact_blocked_person'), "info");
                    // console.warn("Selected record is blocked.");
                    return;
                }
        
                // Populate form state with the selected record details if not blocked
                populateFormState(transType, selectedRecord, selectedId);
                submitForm(e); // Submit form with updated formState
            } else {
                // console.warn('No matching record found for selectedId:', selectedId);
                showToast(t("validation_messages.record_not_found"), "error");
            }
        };

        // Handle Block Person
        const handleBlockPerson = async (e) => {
            e.preventDefault();
        
            if (!selectedId) {
                showToast(t("validation_messages.select_record_first"), "error");
                return;
            }
        
            // Determine the data source and transaction type
            const isCattleTab = activeTab === 'tab1';
            const data = isCattleTab ? boardCattleData.returned_records : boardItemsData.returned_records;
            const transType = isCattleTab ? TRANS_TYPE_BOARD_BLOCK_CONTACT_CATTLE : TRANS_TYPE_BOARD_BLOCK_CONTACT_ITEMS;
        
            // Find the selected record by ID
            const selectedRecord = data.find(record => record.id === selectedId);
        
            if (selectedRecord) {
                // Check if the selected record is already blocked
                if (selectedRecord.blocked === '1') {
                    showToast(t('market_place_page.already_blocked'), "info"); 
                    // console.warn("Selected record is already blocked.");
                    return;
                }else{

                    // Populate form state with the selected record details to block
                    populateFormState(transType, selectedRecord, selectedId);
                    // Ask user to confirm blocking or cancel. once blocked can't be unblocked
                    handleShowSwal(e);
                }
            } else {
                // console.warn('No matching record found for selectedId:', selectedId);
                showToast(t("validation_messages.record_not_found"), "error");
            }
        };

        const handleShowSwal = (e) => {
            Swal.fire({
                title: t('market_place_page.title_blocking_warning'), 
                text: t('market_place_page.text_blocking_warning'), 
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t('market_place_page.confirm_block_button'),
                cancelButtonText: t('market_place_page.confirm_block_cancel'),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
            }).then((result) => {
                if (result.isConfirmed) {

                    //  if confirmed then run the following. uncomment submitForm(e)
                    submitForm(e); // Submit form with updated formState

                } else {

                    //  if not confirmed then stop 
                    toast.success(t('market_place_page.note_not_blocking')); 
                    
                }
            });
        };

        // Submit form with the populated formState
        const submitForm = (e) => {
            e.preventDefault(); // Prevent form reload

            // console.log('formState that I need to send: ', formState);

            // Call handleSubmit with the prepared formState and other parameters
            handleSubmit(
                e,
                formState,
                createResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE,
                setResponseErrors,
                setCreateNotice,
                resetFormState,
                publish,
                t
            );

            // TODO: if a person is contacted, implement the contact flow from here
            toast.success('TODO: needs to complete this and remove toast');
        };

        // ------------ start common effect for cattle items ----------------        

    return (
        <div className="overflow-hidden sm:rounded-md">
            <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="mt-1 md:col-span-2 md:mt-0">
                    <ul className="nav">
                            <TabNavItem 
                                title={t('market_place_page.mapping_cattle')} 
                                id="tab1"
                                activeTab={activeTab} 
                                setActiveTab={setActiveTab}
                                handleChange={() => handleChangeCattle("tab1", "tab1")}
                            />

                            <TabNavItem 
                                title={t('market_place_page.item_header')} 
                                id="tab2" 
                                activeTab={activeTab} 
                                setActiveTab={setActiveTab}
                                handleChange={() => handleChangeItems("tab2", "tab2")}
                            />
                    </ul>
                    <div className="outlet">
                        {/* Cattle tab */}
                        <TabContent id="tab1" activeTab={activeTab}>
                            <div className="text-sm"> 
                                {formattedCattleData && formattedCattleData.length > 0 ? (
                                    <form autoComplete="off" className="w-full">
                                        <div className="overflow-hidden sm:rounded-md">
                                            <div className="col-span-6 lg:col-span-3">
                                                <div className="w-full flex justify-center">
                                                    <EditDelTableDataView
                                                        tableData={formattedCattleData}
                                                        idUsage={0}
                                                        useSerial={tableSettings.useSerial}
                                                        mapHash={{
                                                            "#": "#",
                                                            list_id: 'Advert',
                                                            description: 'Description',
                                                            interest_date: 'Advertised',
                                                            // purchaser_id: 'Purchaser',
                                                            contact_id: 'Person',
                                                            contacted: 'Contacted',
                                                            blocked: 'Blocked',
                                                        }}
                                                        selectedId={selectedId}
                                                        onSelectId={handleOnSelectId}
                                                    />
                                                </div>
                                            </div>
                                            <div className="hidden lg:block lg:col-span-1">{/* spacer */}</div>
                                        </div>

                                        <Footer
                                            // respNotice={createNotice}
                                            respNotice={''} // don't want to fire this
                                            setRespNotice={setCreateNotice}
                                            backLink="/fe/reports"
                                            footerType={footerType}
                                            onContactPerson={handleContactPerson}  
                                            onBlockPerson={handleBlockPerson}   
                                        />
                                        
                                    </form>
                                ) : (
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="col-span-6 lg:col-span-3">
                                            <div className="w-full flex justify-center">
                                                <div>{t('market_place_page.not_cattle_for_sale')}</div> 
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </TabContent>

                        {/* Items tab */}
                        <TabContent id="tab2" activeTab={activeTab}>
                            <div className="text-sm"> 
                                {formattedItemsData && formattedItemsData.length > 0 ? (
                                    <form autoComplete="off" className="w-full">
                                        <div className="overflow-hidden sm:rounded-md">
                                            <div className="col-span-6 lg:col-span-3">
                                                <div className="w-full flex justify-center">
                                                    <EditDelTableDataView
                                                        tableData={formattedItemsData}
                                                        idUsage={0}
                                                        useSerial={tableSettings.useSerial}
                                                        mapHash={{
                                                            "#": "#",
                                                            list_id: 'Advert',
                                                            description: 'Description',
                                                            interest_date: 'Advertised',
                                                            // supplier_id: 'Supplier',
                                                            contact_id: 'Person',
                                                            contacted: 'Contacted',
                                                            blocked: 'Blocked',
                                                        }}
                                                        selectedId={selectedId}
                                                        onSelectId={handleOnSelectId}
                                                    />
                                                </div>
                                            </div>
                                            <div className="hidden lg:block lg:col-span-1"></div>
                                        </div>

                                        <Footer
                                            // respNotice={createNotice}
                                            respNotice={''} // don't want to fire this
                                            setRespNotice={setCreateNotice}
                                            backLink="/fe/reports"
                                            footerType={footerType}
                                            onContactPerson={handleContactPerson}  
                                            onBlockPerson={handleBlockPerson} 
                                        />

                                    </form>
                                ) : (
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="col-span-6 lg:col-span-3">
                                            <div className="w-full flex justify-center">
                                                <div>{t('market_place_page.no_items_needed')}</div> 
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabContent>
        
                    </div>
                
                </div>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick
                        pauseOnHover
                        draggable
                        pauseOnFocusLoss
                        theme="light"
                    />        
                    
            </div>
        </div>

    );

};

export { OpenBoardForm };
